<template>
    <div class="card">
        <div class="card-body card-body-condensed">
            <div class="row">
                <div class="col-12">
                    <table class="table table-borderless table-responsive-sm mb-0">
                        <thead>
                            <tr>
                                <th><i class="mdi mdi-office-building-marker-outline"></i> Cities</th>
                                <th class="text-right" width="70">Users</th>
                                <th class="text-right" width="60">Hits</th>
                            </tr>
                        </thead>
                        <tbody v-if="stats && stats.length">
                            <tr v-for="item in filteredRows" :key="`${item.country}-${item.city}`">
                                <td>{{ item.city ? item.city : 'Can\'t detect' }} {{ item.country ? `/ ${item.country}` : '' }}</td>
                                <td class="text-right">{{ item.users }}</td>
                                <td class="text-right">{{ item.hits }}</td>
                            </tr>
                            <tr v-if="hide_rows">
                                <td colspan="3" class="text-center"> 
                                    <a class="cursor-pointer" v-if="rows_hidden" @click="toggleHideRows()"> <i class="mdi mdi-arrow-down"></i> open {{ rows_count - rows_to_show }} more</a> 
                                    <a class="cursor-pointer" v-else @click="toggleHideRows()"> <i class="mdi mdi-arrow-up"></i> hide</a> 
                                </td>
                            </tr>
                        </tbody>
                        <tfoot v-else>
                            <tr colspan="3">
                                <td>No stats</td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CityCard',
    props: ['stats'],
    data: () => {
        return {
            rows_to_show: 5,
            rows_hidden: true
        }
    },
    computed: {
        rows_count() {
            return this.stats.length || 0
        },
        hide_rows() {
            return this.rows_count > this.rows_to_show
        },
        filteredRows() {
            return  this.stats.filter((item, i) => {
                return !this.rows_hidden || this.rows_count <= this.rows_to_show || i <= this.rows_to_show - 1 
            })
        },
    },
    methods: {
        toggleHideRows() {
            this.rows_hidden = !this.rows_hidden
        }
    }
}
</script>
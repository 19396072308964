<template>
    <div class="card">
        <div class="card-body">
            <h5 class="mt-0" title="Revenue"><i class="mdi mdi-google-analytics"></i> Total stats for a date range</h5>
            <div class="row mt-2">
                <div class="col-sm-6 text-center h3">{{stats && stats.users ? stats.users : 0}} <small class="h4 ml-1">{{ stats.users == 1 ? 'user' : 'users'}}</small></div>
                <div class="col-sm-6 text-center h3">{{stats && stats.hits ? stats.hits : 0}} <small class="h4 ml-1">{{ stats.hits == 1 ? 'hit' : 'hits'}}</small></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TotalStatsCard',
    props: {
        stats: {
            default: {}
        }
    }
}
</script>
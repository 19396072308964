<template>
    <div class="card">
        <div class="card-body card-body-condensed">
            <div class="row">
                <div :class="{ 'col-8': show_chart, 'col-12': !show_chart }">
                    <table class="table table-borderless table-responsive-sm mb-0">
                        <thead>
                            <tr>
                                <th><i class="mdi mdi-cellphone"></i> Mobile devices</th>
                                <th class="text-right" width="70">Users</th>
                                <th class="text-right" width="60">Hits</th>
                            </tr>
                        </thead>
                        <tbody v-if="stats && stats.length">
                            <tr v-for="item in filteredRows" :key="`${item.device_vendor}-${item.device_model}`">
                                <td>
                                    <span class="chart-legend-color-icon" :style="{ backgroundColor: vendor_colors[ item.device_vendor ] }" v-if="show_chart"></span>
                                    {{ item.device_vendor != 'Can\'t detect' ? `${item.device_vendor} / ` : '' }} {{ item.device_model ? item.device_model : 'Can\'t detect' }}
                                </td>
                                <td class="text-right">{{ item.users }}</td>
                                <td class="text-right">{{ item.hits }}</td>
                            </tr>
                            <tr v-if="hide_rows">
                                <td colspan="3" class="text-center"> 
                                    <a class="cursor-pointer" v-if="rows_hidden" @click="toggleHideRows()"> <i class="mdi mdi-arrow-down"></i> open {{ rows_count - rows_to_show }} more</a> 
                                    <a class="cursor-pointer" v-else @click="toggleHideRows()"> <i class="mdi mdi-arrow-up"></i> hide</a> 
                                </td>
                            </tr>
                        </tbody>
                        <tfoot v-else>
                            <tr colspan="3">
                                <td>No stats</td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
                <div class="col-4" v-if="show_chart">
                    <pie-chart class="pt-4" :chartData="chartData"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PieChart from '@/components/charts/PieChart'
import { colors } from '@/plugins/charts/pie'

export default {
    name: 'MobileDeviceVendorCard',
    props: ['stats'],
    data: () => {
        return {
            rows_to_show: 5,
            rows_hidden: true
        }
    },
    computed: {
        rows_count() {
            return this.stats.length || 0
        },
        hide_rows() {
            return this.rows_count > this.rows_to_show
        },
        filteredRows() {
            return  this.stats.filter((item, i) => {
                return !this.rows_hidden || this.rows_count <= this.rows_to_show || i <= this.rows_to_show - 1 
            })
        },
        vendor_object() {
            let vendors = {}
            this.stats.forEach((el) => {
                if(!el.device_vendor) el.device_vendor = 'Can\'t detect'
                if(!vendors[el.device_vendor]) vendors[el.device_vendor] = {
                    users: 0,
                    vendor: el.device_vendor
                }
                vendors[el.device_vendor].users += el.users
            })

            return vendors
        },
        vendor_data() {
            return Object.values( this.vendor_object ).sort((a, b) => {
                if(b.users == a.users) return 0
                return b.users > a.users ? 1 : -1
            })
        },
        vendor_colors() {
            let vcolor = {}

            this.vendor_data.forEach((el, i) => {
                if(i < this.colors_length - 1) {
                    vcolor[el.vendor] = colors[i]
                } else {
                    vcolor[el.vendor] = colors[ this.colors_length - 1 ]
                }
            })

            return vcolor
        },
        show_chart() {
            return this.stats && this.stats.length >= 3
        },
        colors_length() {
            return colors.length
        },
        chartData() {
            let data = [], labels = []

            if(this.vendor_data && this.vendor_data.length)
                this.vendor_data.forEach((el, i) => {
                    let item = el.vendor ? el.vendor : 'Desktop'

                    if(i < this.colors_length - 1) {
                        data[i] = el.users
                        labels[i] = item
                    } else {
                        if(!data[this.colors_length - 1]) data[this.colors_length - 1] = 0
                        data[this.colors_length - 1] += el.users
                        labels[this.colors_length - 1] = 'Others'
                    }
                })
            return {
                datasets: [{
                    data
                }],
                labels
            }
        }
    },
    methods: {
        toggleHideRows() {
            this.rows_hidden = !this.rows_hidden
        }
    },
    components: {
        PieChart
    }
}
</script>
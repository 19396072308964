<template>
    <div class="card">
        <div class="card-body">
            <div class="float-right">
                <i class="mdi mdi-desktop-classic"></i>
            </div>
            <h5 class="text-muted font-weight-normal mt-0" title="Revenue">Mobile devices</h5>
            <table class="table table-borderless table-responsive-sm mb-0" v-if="stats && stats.length">
                <tr>
                    <th></th>
                    <th class="text-right" width="70">Users</th>
                                <th class="text-right" width="60">Hits</th>
                </tr>
                <tr v-for="item in filteredRows">
                    <td>{{ item.device_model ? item.device_model : 'Can\'t detect' }}</td>
                    <td class="text-right">{{ item.users }}</td>
                    <td class="text-right">{{ item.hits }}</td>
                </tr>
                <tr v-if="hide_rows">
                    <td colspan="3" class="text-center"> 
                        <a class="cursor-pointer" v-if="rows_hidden" @click="toggleHideRows()"> <i class="mdi mdi-arrow-down"></i> open {{ rows_count - rows_to_show }} more</a> 
                        <a class="cursor-pointer" v-else @click="toggleHideRows()"> <i class="mdi mdi-arrow-up"></i> hide</a> 
                    </td>
                </tr>
            </table>
            <p v-else>No stats</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MobileDeviceCard',
    props: ['stats'],
    data: () => {
        return {
            rows_to_show: 5,
            rows_hidden: true
        }
    },
    computed: {
        rows_count() {
            return this.stats.length || 0
        },
        hide_rows() {
            return this.rows_count > this.rows_to_show
        },
        filteredRows() {
            return  this.stats.filter((item, i) => {
                return !this.rows_hidden || this.rows_count <= this.rows_to_show || i <= this.rows_to_show - 1 
            })
        },
    },
    methods: {
        toggleHideRows() {
            this.rows_hidden = !this.rows_hidden
        }
    }
}
</script>
<script>
import { Bar, mixins } from 'vue-chartjs'
import ChartDataLabels from 'chartjs-plugin-datalabels';
const { reactiveProp } = mixins

import { findLastIndex } from '@/plugins/utils'

export default {
    extends: Bar,
    mixins: [reactiveProp],
    props: ['chartData'],
    mounted () {
        this.addPlugin(ChartDataLabels)

        this.renderChart(this.chartData, {
            responsive: true,
            maintainAspectRatio: false,
            animation: {
                duration: 450,
                easing: 'linear'
            },
            layout: {
                padding: {
                    top: 20
                }
            },
            scales: {
                xAxes: [{
                    stacked: true,
                    type: 'time',
                    scaleLabel: {
                        labelString: 'Date',
                        display: true,
                    },
                    distribution: 'linear',
                    time: {
                        unit: 'day',
                        tooltipFormat: 'DD.MM.YYYY HH:00:00'
                    },
                    offset: true,
                    bounds: 'ticks'
                }],
                yAxes: [{
                    stacked: true
                }]
            },
            plugins: {
                datalabels: {
                    align: 'end',
                    anchor: 'end',
                    backgroundColor: function(context) {
                        return `${context.dataset.backgroundColor}ff`
                    },
                    borderRadius: 4,
                    color: 'white',
                    font: {
                        size: 11,
                        weight: 600
                    },
                    offset: 1,
                    formatter: function(value, context) {
                        let data = context.dataset.data
                        let max = Math.max.apply(Math, data.map(function(o) { return o.y; }))
                        let index = findLastIndex(data, 'y', max)

                        return context.dataIndex === index ? value.y : null
                    }
                }
            },
        })
    }
}
</script>
<script>
import { Pie, mixins } from 'vue-chartjs'
import ChartDataLabels from 'chartjs-plugin-datalabels';
const { reactiveProp } = mixins

import { colors } from '@/plugins/charts/pie'

export default {
    extends: Pie,
    mixins: [reactiveProp],
    props: ['chartData'],
    mounted () {
        this.addPlugin(ChartDataLabels)

        this.renderChart(this.chartData, {
            showAllTooltips: true,
            responsive: true,
            maintainAspectRatio: true,
            animation: {
                duration: 450,
                easing: 'linear'
            },
            legend: {
                display: false
            },
            elements: {
                arc: {
                    backgroundColor: colors
                }
            },
            tooltips: {
                callbacks: {
                    label: function(tooltipItem, data) {
                        var dataset = data.datasets[tooltipItem.datasetIndex];
                        var meta = dataset._meta[Object.keys(dataset._meta)[0]];
                        var total = meta.total;
                        var currentValue = dataset.data[tooltipItem.index];
                        var percentage = parseFloat((currentValue/total*100).toFixed(1));

                        return ' '+ currentValue + ' (' + percentage + '%)';
                    },
                    title: function(tooltipItem, data) {
                        return data.labels[tooltipItem[0].index];
                    }
                }
            },
            plugins: {
                datalabels: {
                    color: 'white',
                    font: {
                        size: 11,
                        weight: 600
                    },
                    formatter: function(value, context) {
                        let sum = context.dataset.data.reduce((el, acc) => acc += el, 0)
                        let perc = (value * 100 / sum).toFixed(1)

                        return +perc >= 15 ? `${perc}%` : null
                    }
                }
            },
        })
    }
}
</script>
<template>
    <div class="card">
        <div class="card-body card-body-condensed">
            <div class="row">
                <div :class="{ 'col-8': show_chart, 'col-12': !show_chart }">
                    <table class="table table-borderless table-responsive-sm mb-0">
                        <thead>
                            <tr>
                                <th><i class="mdi mdi-translate"></i> Languages</th>
                                <th class="text-right" width="70">Users</th>
                                <th class="text-right" width="60">Hits</th>
                            </tr>
                        </thead>
                        <tbody v-if="stats && stats.length">
                            <tr v-for="(item, i) in filteredRows" :key="`lang-${item.language}`">
                                <td>
                                    <span class="chart-legend-color-icon" :style="{ backgroundColor: get_color(i) }" v-if="show_chart"></span>
                                    {{ item.language ? lang_code(item.language) : 'Can\'t detect' }}
                                </td>
                                <td class="text-right">{{ item.users }}</td>
                                <td class="text-right">{{ item.hits }}</td>
                            </tr>
                            <tr v-if="hide_rows">
                                <td colspan="3" class="text-center"> 
                                    <a class="cursor-pointer" v-if="rows_hidden" @click="toggleHideRows()"> <i class="mdi mdi-arrow-down"></i> open {{ rows_count - rows_to_show }} more</a> 
                                    <a class="cursor-pointer" v-else @click="toggleHideRows()"> <i class="mdi mdi-arrow-up"></i> hide</a> 
                                </td>
                            </tr>
                        </tbody>
                        <tfoot v-else>
                            <tr colspan="3">
                                <td>No stats</td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
                <div class="col-4" v-if="show_chart">
                    <pie-chart class="pt-4" :chartData="chartData"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PieChart from '@/components/charts/PieChart'
import ISO6391 from 'iso-639-1'

import { colors } from '@/plugins/charts/pie'

export default {
    name: 'LanguagesStatsCard',
    props: ['stats'],
    data: () => {
        return {
            rows_to_show: 5,
            rows_hidden: true
        }
    },
    computed: {
        rows_count() {
            return this.stats.length || 0
        },
        hide_rows() {
            return this.rows_count > this.rows_to_show
        },
        filteredRows() {
            return  this.stats.filter((item, i) => {
                return !this.rows_hidden || this.rows_count <= this.rows_to_show || i <= this.rows_to_show - 1 
            })
        },
        show_chart() {
            return this.stats && this.stats.length >= 3
        },
        colors_length() {
            return colors.length
        },
        chartData() {
            let data = [], labels = []

            if(this.stats && this.stats.length)
                this.stats.forEach((el, i) => {
                    let lang = el.language ? ISO6391.getName(el.language) : 'Can\'t detect'
                    if(!lang) lang = 'Can\'t detect'

                    if(i < this.colors_length - 1) {
                        data[i] = el.users
                        labels[i] = lang
                    } else {
                        if(!data[this.colors_length - 1]) data[this.colors_length - 1] = 0
                        data[this.colors_length - 1] += el.users
                        labels[this.colors_length - 1] = 'Others'
                    }
                })
            return {
                datasets: [{
                    data
                }],
                labels
            }
        }
    },
    methods: {
        get_color(i) {
            return i >= this.colors_length - 1 ? colors[this.colors_length - 1] : colors[i]
        },
        lang_code(code) {
            let lang = ISO6391.getName(code)
            return lang ? lang : 'Can\'t detect'
        },
        toggleHideRows() {
            this.rows_hidden = !this.rows_hidden
        },
    },
    components: {
        PieChart
    }
}
</script>
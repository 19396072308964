<template>
    <div>
        <div class="row">
            <div class="col-11">
                <div class="form-group">
                    <date-range-form :range="form.dates" @input="form.dates = $event" :opens="`right`" />
                </div>
            </div>
            <div class="col-1">
                <div class="form-group text-right">
                    <button class="btn btn-outline-input btn-block" @click="data()" v-if="!loading"><i class="mdi mdi-reload"></i></button>
                    <button class="btn btn-outline-input btn-block" type="button" disabled v-else>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span class="sr-only"></span>
                    </button>
                </div>
            </div>
        </div>

        <div style="overflow:hidden" v-if="loading">
            <loader-line />
        </div>
        <div v-else>
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <time-series-chart :chartData="timestatsChart"/>
                        </div>
                    </div>
                </div>
            </div>

            <masonry ref="masonry" :cols="{default: 2, 1370: 1, 990: 1}" :gutter="{default: '24px', 1200: '20px', 990: '10px'}">
                <total-stats-card :stats="total" />

                <device-type-stats-card :stats="device_types" />

                <mobile-device-vendor-stats-card :stats="mobile_device_vendor" />

                <referer-stats-card :stats="referers" />

                <language-stats-card :stats="languages" />

                <country-stats-card :stats="countries" />

                <city-stats-card :stats="cities" />            
            </masonry>
        </div>
    </div> 
</template>

<script>
import TimeSeriesChart from '@/components/charts/TimeSeries'
import DateRangeForm from '@/components/form/DateRange.vue'
import LoaderLine from '@/components/util/LoaderLine'
import dayjs from '@/plugins/day'

import TotalStatsCard from '@/components/stats/Total'
import LanguageStatsCard from '@/components/stats/Language'
import DeviceTypeStatsCard from '@/components/stats/DeviceType'
import CountryStatsCard from '@/components/stats/Country'
import RefererStatsCard from '@/components/stats/Referer'
import CityStatsCard from '@/components/stats/City'
import MobileDeviceStatsCard from '@/components/stats/MobileDevice'
import MobileDeviceVendorStatsCard from '@/components/stats/MobileDeviceVendor'

export default {
    name: 'Dashboard',
    data: () => {
        return {
            loading: false,
            timestats: [],
            languages: [],
            referers: [],
            countries: [],
            cities: [],
            device_types: [],
            mobile_device_vendor: [],
            total: {},
            form: {
                dates: {
                    startDate: dayjs().add(-7, 'day').startOf('day'),
                    endDate: dayjs().endOf('day')
                },
            }
        }
    },
    computed: {
        timestatsChart() {
            return {
                datasets: [
                    {
                        barPercentage: 1,
                        categoryPercentage: 1,
                        label: 'Users',
                        maxBarThickness: 16,
                        backgroundColor: '#56aee2',
                        data: this.timestats.map(el => {
                            return {
                                x: el.hour,
                                y: el.users
                            }
                        })
                    },
                    {
                        barPercentage: 1,
                        maxBarThickness: 12,
                        label: 'Hits',
                        backgroundColor: '#5668e2',
                        data: this.timestats.map(el => {
                            return {
                                x: el.hour,
                                y: el.hits
                            }
                        })
                    },
                    
                ]
            }
        },
        campaign() {
            return this.$route.params.campaign
        }
    },  
    async beforeMount() {
		await this.data()
    },
    methods: {
        async data() {
            this.loading = true

            var [err, response] = await this.$orm.queryRAW({
                query: `
                    query GetDashboardStats($startDate: timestamptz, $endDate: timestamptz, $campaign_id: bigint) {
                        stats_campaign_total_byhour (
                                where: {
                                    _and: [
                                        {hour: {_gte: $startDate}}, 
                                        {hour: {_lte: $endDate}},
                                        {campaign_id: {_eq: $campaign_id}}
                                    ]
                                },
                                order_by: { hour: asc }
                            ){
                                hour
                                hits
                                users
                            }
                        stats_campaign_total_function (
                                args: {
                                    date_from: $startDate, 
                                    date_to: $endDate,
                                    camp_id: $campaign_id
                                },
                                order_by: { users: desc }
                            ){
                                hits
                                users
                            }
                        stats_campaign_language_function (
                                args: {
                                    date_from: $startDate, 
                                    date_to: $endDate,
                                    camp_id: $campaign_id
                                },
                                order_by: { users: desc }
                            ){
                                language
                                hits
                                users
                            }
                        stats_campaign_referer_function (
                                args: {
                                    date_from: $startDate, 
                                    date_to: $endDate,
                                    camp_id: $campaign_id
                                },
                                order_by: { users: desc }
                            ){
                                referer
                                hits
                                users
                            }
                        stats_campaign_country_function (
                                args: {
                                    date_from: $startDate, 
                                    date_to: $endDate,
                                    camp_id: $campaign_id
                                },
                                order_by: { users: desc }
                            ){
                                country
                                hits
                                users
                            }
                        stats_campaign_city_function (
                                args: {
                                    date_from: $startDate, 
                                    date_to: $endDate,
                                    camp_id: $campaign_id
                                },
                                order_by: { users: desc }
                            ){
                                country
                                city
                                hits
                                users
                            }
                        stats_campaign_device_type_function (
                                args: {
                                    date_from: $startDate, 
                                    date_to: $endDate,
                                    camp_id: $campaign_id
                                },
                                order_by: { users: desc }
                            ){
                                device_type
                                hits
                                users
                            } 
                        stats_campaign_mobile_device_vendor_function (
                                args: {
                                    date_from: $startDate, 
                                    date_to: $endDate,
                                    camp_id: $campaign_id
                                },
                                order_by: { users: desc }
                            ){
                                device_model
                                device_vendor
                                hits
                                users
                            }
                    }
                `,
                variables: {
                    startDate: this.form.dates.startDate,
                    endDate: this.form.dates.endDate,
                    campaign_id: +this.campaign
                }
            })
            setTimeout(() => {
                this.loading = false
            }, 250)

            if(err) {
                console.error('/views/dashboard', 'methods.data', '$orm.queryRAW', err)
                return $.NotificationApp.send("Error", "Can't get dashboard stats", "top-right", "", "error")
            }
            
            console.debug('/views/dashboard', 'methods.data', '$orm.queryAndSubscribe', response)
        
            this.timestats = response.stats_campaign_total_byhour || []
            this.total = response.stats_campaign_total_function[0] || {}
            this.languages = response.stats_campaign_language_function || []
            this.referers = response.stats_campaign_referer_function || []
            this.countries = response.stats_campaign_country_function || []
            this.cities = response.stats_campaign_city_function || []
            this.device_types = response.stats_campaign_device_type_function || []
            this.mobile_device_vendor = response.stats_campaign_mobile_device_vendor_function || []

            this.$nextTick(() => {
                this.$refs.masonry.reCalculate();
            });
        }
    },
    watch: {
        'form.dates'() {
            if(this.form.dates.endDate.toString() == this.form.dates.startDate.toString()) {
                this.form.dates.endDate = dayjs(this.form.dates.endDate).add(1, 'day')
            }
            this.data()
        },
        '$route.params.campaign'() {
            this.data()
        }
    },
    components: {
        TimeSeriesChart,
        DateRangeForm,
        TotalStatsCard,
        LanguageStatsCard,
        DeviceTypeStatsCard,
        CountryStatsCard,
        RefererStatsCard,
        CityStatsCard,
        MobileDeviceStatsCard,
        MobileDeviceVendorStatsCard,
        LoaderLine
    }
}
</script>